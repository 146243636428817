.content {
    margin-top: 30px;
    display: inline-grid;
}

.query {
    display: inline-flex;
}

.query input {
    min-width: 500px;
}

.result {
    margin-top: 20px;
    display: inline-block;
}